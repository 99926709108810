<template>
  <div class="church-presentation-template">
    <page-header>
      <h1 class="d-none">{{ translations.tcAddEditChurchAddress }}</h1>
    </page-header>
    <page-body class>
      <address-form v-if='translations.components["address-form"].tcSelectAType' :churchName="churchName"
        :churchKey="userSelectedChurchKey" :address="userSelectedAddress"
        :i18n="translations.components"></address-form>
    </page-body>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import addressForm from '@/components/AddressForm.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'add-edit-church-address',
  mixins: [translationMixin],
  data() {
    return {
      translations: {
        components: {
          'address-form': {},
        },
      },
      churchName: null,
    }
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async getChurchName() {
      this.churchName = this.churches.find((p) => p.chu_org_key === this.userSelectedChurchKey).church_name
    },
  },
  async created() {
    await this.setLoadingStatus(true)
    const results = await Promise.all([
      this.getViewTranslations(),
      this.getComponentTranslations('address-form'),
      await this.getChurchName(),
    ])
    const componentTranslations = results[1]
    this.$set(this.translations, 'components', componentTranslations)
    await this.setLoadingStatus(false)
  },
  computed: {
    ...mapGetters({
      churches: 'churchMinistry/churches',
      userIndKey: 'user/userId',
      userName: 'user/userName',
      userSelectedChurchKey: 'user/userSelectedChurchKey',
      userSelectedAddress: 'user/userSelectedAddress',
      prefCulture: 'user/userPreferredCulture',
    }),
  },
  components: {
    pageHeader: pageHeader,
    pageBody: pageBody,
    pageFooter: pageFooter,
    iTooltip: iTooltip,
    addressForm: addressForm,
  }
}
</script>

<style scoped>
.page-body {
  padding: 80px 15px;
}
</style>
